.custom-icon-ios {
  font-size: 3rem; /* 调整你想要的图标大小 */
  /*float: left;*/
  /*display:inline;*/
  margin-left: 1rem;
}
.custom-icon-android {
  font-size: 3rem; /* 调整你想要的图标大小 */
  /*float: left;*/
  /*display:inline;*/
  margin-left: 1.8rem;
}